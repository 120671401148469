<template>
  <v-container>
    <!-- keyboard -->
    <v-row id="keyboard" justify="center">
      <v-col xs="12" sm="6" md="6" lg="4" xl="3">
        <v-row justify="center">
          <v-col cols="12">
            <input
              :style="{ width: '100%', background: backGroundColor }"
              class="kakko-input-blank"
              type="text"
              minlength="1"
              required="required"
              v-if="selectedLetter"
              :value="`${selectedLetter
                .split('')
                .filter((l) => l !== '્')
                .join(' + ')} = ${selectedLetter}`"
              :readonly="true"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <HowToPlay :i18n_instructions_key="'jointWordsInstruction'" />
        </v-row>

        <v-row justify="center">
          <v-col
            class="pa-1 ma-1 words"
            v-for="(w, key) in jointLetterWords"
            :key="key"
            :style="[
              w.selected ? { border: '1px solid black' } : { border: 'none' }
            ]"
            @click="answerSelected(w)"
          >
            <LongPressTranslationHelper
              :word="{
                gu: w.word,
                en: $store.state.wordList.level3[w.word].english,
                hi: $store.state.wordList.level3[w.word].hindi
              }"
            >
              {{ w.word }}
            </LongPressTranslationHelper>
          </v-col>
        </v-row>

        <v-row justify="center" v-if="!practiceOver">
          <v-col class="text-center">
            <v-row justify="center">
              <v-btn
                v-if="selectedLetterIndex < letters.length"
                raised
                color="primary"
                @click="submit()"
                >{{ $t('Submit Answer') }}</v-btn
              >
            </v-row>
            <v-row justify="center" class="ma-4">
              <b>Score:</b>
              <v-icon class="ml-2 mr-2" color="green"
                >mdi-check-circle-outline</v-icon
              >
              <span class="mr-4">{{ score.correct }}</span>
              <v-icon class="mr-2" color="primary"
                >mdi-close-circle-outline</v-icon
              >{{ score.wrong }}
            </v-row>
          </v-col>
          <AreYouSure
            :visible="areYouSureDialog"
            :cardText="
              jointLetterWords.filter((w) => w.selected).length === 0
                ? $t('You have not made any selection')
                : ''
            "
            @areYouSure="areYouSure"
            @close="areYouSureDialog = false"
          ></AreYouSure>
          <RightWrongDialog
            :visible="showAnswersDialog"
            :right="rightAnswers"
            :wrong="wrongAnswers"
            :notSelectedAnswers="notSelectedAnswers"
            @close="closeShowAnswersDialog"
          ></RightWrongDialog>
        </v-row>
        <v-row justify="center" v-else>
          <SkillLevel :score="score" />
          <v-btn class="mt-2" raised color="primary" @click="refresh()">{{
            $t('Try Again')
          }}</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <GoBackButon />
  </v-container>
</template>

<script>
import GoBackButon from './GoBackButton'
import Utils from '../util/Utils'
import HowToPlay from './HowToPlay'
import SkillLevel from './SkillLevel'
import AreYouSure from './AreYouSure'
import RightWrongDialog from './RightWrongDialog'
import LongPressTranslationHelper from './LongPressTranslationHelper.vue'
export default {
  mounted() {
    this.letters = this.$store.state.jointWords.map((w) => w.letter)
    this.refresh()
  },
  components: {
    GoBackButon,
    HowToPlay,
    SkillLevel,
    AreYouSure,
    RightWrongDialog,
    LongPressTranslationHelper
  },
  computed: {
    selectedLetter() {
      return this.letters[this.selectedLetterIndex]
    },
    rightAnswers() {
      return this.jointLetterWords
        .filter((w) => w.selected && w.word.includes(this.selectedLetter))
        .map((w) => w.word)
    },
    wrongAnswers() {
      return this.jointLetterWords
        .filter((w) => w.selected && !w.word.includes(this.selectedLetter))
        .map((w) => w.word)
    },
    notSelectedAnswers() {
      return this.jointLetterWords
        .filter((w) => !w.selected && w.word.includes(this.selectedLetter))
        .map((w) => w.word)
    }
  },
  methods: {
    answerSelected(word) {
      word.selected = !word.selected
    },

    submit() {
      this.areYouSureDialog = true
    },
    proceedToNext() {
      if (this.selectedLetterIndex + 1 < this.letters.length) {
        // update score
        const score = { ...this.score }
        score.correct += this.jointLetterWords.filter(
          (w) => w.selected && w.word.includes(this.selectedLetter)
        ).length
        score.wrong += this.jointLetterWords.filter(
          (w) => w.selected && !w.word.includes(this.selectedLetter)
        ).length
        score.wrong += this.jointLetterWords.filter(
          (w) => !w.selected && w.word.includes(this.selectedLetter)
        ).length
        this.score = score
        this.selectedLetterIndex++
        this.selectQuestion()
      } else {
        this.practiceOver = true
      }
    },
    closeShowAnswersDialog() {
      this.showAnswersDialog = false
      this.proceedToNext()
    },
    areYouSure(answer) {
      if (answer) {
        this.showAnswersDialog = true
      }
      this.areYouSureDialog = false
    },
    refresh() {
      this.score = { total: 0, correct: 0, wrong: 0 }
      this.selectQuestion()
      this.selectedLetterIndex = 0
      this.practiceOver = false
    },
    selectQuestion() {
      var words = [
        ...Object.keys(this.$store.state.wordList.level3).filter((w) => {
          return w.includes(this.selectedLetter)
        }),
        ...Object.keys(this.$store.state.wordList.level3)
          .filter((w, i) => {
            return !w.includes(this.selectedLetter)
          })
          .slice(0, 20)
      ]

      this.score.total += words.filter((w) =>
        w.includes(this.selectedLetter)
      ).length

      this.jointLetterWords = Utils.shuffle(words).map((w) => {
        return { word: w, selected: false }
      })
    }
  },
  data: () => ({
    showAnswersDialog: false,
    practiceOver: false,
    areYouSureDialog: false,
    dialog: false,
    letters: [],
    jointLetterWords: [],
    selectedLetterIndex: 0,
    backGroundColor: 'white',
    score: { total: 0, correct: 0, wrong: 0 }
  })
}
</script>

<style scoped>
.correctSelection {
  border: 1px solid black;
}
.words {
  padding: 1px;
  margin: 5px;
  text-shadow: 0 1px 1px #eee;
  font-size: 25px;
  text-align: center;
}

/* D. keyboard */
#keyboard {
  margin-left: 2px;
  margin-right: 2px;
}
.keyboardWidth {
  max-width: 400px;
}
.keyboard-row-letter {
  height: 40px;
  width: 40px;
  font-size: x-large;
}
.blink {
  animation: blink 1s linear infinite;
  color: red;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
