var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"id":"keyboard","justify":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.selectedLetter)?_c('input',{staticClass:"kakko-input-blank",style:({ width: '100%', background: _vm.backGroundColor }),attrs:{"type":"text","minlength":"1","required":"required","readonly":true},domProps:{"value":`${_vm.selectedLetter
              .split('')
              .filter((l) => l !== '્')
              .join(' + ')} = ${_vm.selectedLetter}`}}):_vm._e()])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('HowToPlay',{attrs:{"i18n_instructions_key":'jointWordsInstruction'}})],1),_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.jointLetterWords),function(w,key){return _c('v-col',{key:key,staticClass:"pa-1 ma-1 words",style:([
            w.selected ? { border: '1px solid black' } : { border: 'none' }
          ]),on:{"click":function($event){return _vm.answerSelected(w)}}},[_c('LongPressTranslationHelper',{attrs:{"word":{
              gu: w.word,
              en: _vm.$store.state.wordList.level3[w.word].english,
              hi: _vm.$store.state.wordList.level3[w.word].hindi
            }}},[_vm._v(" "+_vm._s(w.word)+" ")])],1)}),1),(!_vm.practiceOver)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.selectedLetterIndex < _vm.letters.length)?_c('v-btn',{attrs:{"raised":"","color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('Submit Answer')))]):_vm._e()],1),_c('v-row',{staticClass:"ma-4",attrs:{"justify":"center"}},[_c('b',[_vm._v("Score:")]),_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]),_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.score.correct))]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-close-circle-outline")]),_vm._v(_vm._s(_vm.score.wrong)+" ")],1)],1),_c('AreYouSure',{attrs:{"visible":_vm.areYouSureDialog,"cardText":_vm.jointLetterWords.filter((w) => w.selected).length === 0
              ? _vm.$t('You have not made any selection')
              : ''},on:{"areYouSure":_vm.areYouSure,"close":function($event){_vm.areYouSureDialog = false}}}),_c('RightWrongDialog',{attrs:{"visible":_vm.showAnswersDialog,"right":_vm.rightAnswers,"wrong":_vm.wrongAnswers,"notSelectedAnswers":_vm.notSelectedAnswers},on:{"close":_vm.closeShowAnswersDialog}})],1):_c('v-row',{attrs:{"justify":"center"}},[_c('SkillLevel',{attrs:{"score":_vm.score}}),_c('v-btn',{staticClass:"mt-2",attrs:{"raised":"","color":"primary"},on:{"click":function($event){return _vm.refresh()}}},[_vm._v(_vm._s(_vm.$t('Try Again')))])],1)],1)],1),_c('GoBackButon')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }